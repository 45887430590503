import React from "react";

const PowerBI: React.FC = () => {
  return (
    <>
      <div className="p-3 mr-5">
        <div className="row">
          <div className="page-title">Power BI</div>
          <div className="mr-5 mt-3" style={{ width: "100%", height: "90vh" }}>
            <iframe
              title="Sales"
              width="100%"
              height="100%"
              src="https://app.powerbi.com/reportEmbed?reportId=98529fb1-2767-4b68-a5aa-7507738ff554&autoAuth=true&ctid=deabcf1b-e6d9-4c29-bc24-35d874422372"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default PowerBI;
