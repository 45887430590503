import axios from "axios";

class ApiService {
  private headersConfig: any = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("access_token"),
  };

  public async get(url: string, params = {}) {
    return this.makeRequest("get", url, params);
  }

  public async post(url: string, data = {}) {
    return this.makeRequest("post", url, {}, data);
  }

  public async put(url: string, data = {}) {
    return this.makeRequest("put", url, {}, data);
  }

  public async delete(url: string, params = {}) {
    return this.makeRequest("delete", url, params);
  }

  public async multiPost(url: string, data = {}) {
    this.headersConfig["Content-Type"] = "multipart/form-data";
    return this.makeRequest("post", url, {}, data);
  }

  public async blob(url: string, data = {}) {
    return this.makeRequest("get", url, {}, data, "blob");
  }

  public makeRequest = async (
    method: string,
    url: string,
    params = {},
    data = {},
    responseType: any = "json"
  ) => {
    try {
      const response = await axios({
        method: method,
        url: url,
        headers: this.headersConfig,
        params: params,
        data: data,
        responseType: responseType,
      });
      return response.data;
    } catch (err: any) {
      console.log("error on makeRequest " + err);
      const message =
        err.response?.data?.error ?? err.response?.data?.message ?? err.message;
      throw new Error(JSON.stringify(message));
    }
  };
}

export default ApiService;
